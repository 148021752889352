// Here you can add other styles
:root {
    //--cui-border-color-translucent: var($gray-100)//#e9eaf3;
    --cui-border-color-translucent: #e9eaf3be;
    //--cui-accordion-active-bg: #433164 !important;
    --cui-body-line-height: 1.4;
}
.btn {
    --cui-btn-font-size: 0.8rem;
}

.displayTable {
    //#12056d
    ////background-color: linear-gradient(#433164 50%, #8289de) !important;
    background-color: unset !important;
    //background-image: linear-gradient(#433164 50%, #8289de) !important; //#6B2EDB
    background-image: linear-gradient(#12093D 50%, #3A298F) !important; //#6B2EDB
}
.sidebar {
--cui-sidebar-brand-bg: #12093D;//#433164;
--cui-sidebar-bg: linear-gradient(#12093D 50%, #3A298F);
//--cui-sidebar-bg: linear-gradient(#433164 50%, #8289de);

}
.header {
    --cui-header-bg:#fff;
    opacity: 0.95;
}
.bg-light {
    //--cui-bg-opacity: 1;
    background-color: #FDFDFF !important//#f4f4f8d5 !important; //#FDFDFF - kolor kart
    //background-color: #fff !important;
    //background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important; 
    
}
/*
.card-ai {
    //border: unset;// #e9eaf3;
    .card-body {
        padding-right: unset;
        padding-left: unset;
        .table {
            --cui-table-striped-bg: unset;//#f4f4f8d5
            --cui-table-hover-bg: #f4f4f8d5;
        }
    }
}*/
.card-header-ai {
    border-bottom: unset;
    background-color: #fff;
    //text-align: center !important;
    font-weight: 600;
    font-size: 1.5rem;
    color:  $primary-dark;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: whitesmoke !important;
    //background-color: var(--cui-btn-active-bg);
    //border-color: var(--cui-btn-active-border-color);
}


.border {
    border: unset !important;
}
/*
thead, tbody, tfoot, tr, td, th {
    //border-color: inherit;
    border-style: unset;
    padding-bottom: 16px !important;
    padding-top: 8px !important;
    font-size: $font-size-sm !important;
}
*/

thead, tbody, tfoot, tr, td, th {
    //border-color: inherit;
    //border-style: unset;
    
    //border-style: solid;
    //padding-bottom: 16px !important;
    //padding-top: 8px !important;
    font-size: $font-size-sm !important;
    /*&:last-child {
        border-bottom: unset;
    }*/
}
/*
tr {
    //border: 1px solid $black;
    padding: 20px !important;
    c-card {
        border: unset !important;
        c-card-body {
            border: 1px solid #e9eaf3be;
            border-radius: $border-radius;
        }
    }
}*/
.table > :not(caption) > * > * {
    //border-bottom: unset;
    
    padding: unset;
    //padding-bottom: 4px;
}
.table {
    //--cui-table-striped-bg: unset;//#f4f4f8d5
    --cui-table-hover-bg: #f4f4f8d5;
}
/*
.aimars-table {
    thead {
        ////color: #807397 !important;
        color: $secondary !important;
    }
}*/


.table-light {
    --cui-table-bg: unset;

}
/*
h2, h3, h6 {
    color: $primary !important;
}*/
h1,h2 {
    font-weight: $headings-font-weight !important;
}
h6 {
    color: $primary !important;
    font-weight: normal !important;
}
/*
a .ai-link {

    color: var(--cui-link-color) !important;
}


.ai-link-light {

    color: #807397 !important;
}

.ai-link-light:hover {

    color: #d2cae1 !important;
}*/

.link-primary {
    color: $primary;
    font-weight: normal;
    cursor: pointer;
    &:hover {
        //font-weight: bold;
        text-decoration: underline;
    }
}
.link-primary-thin {
    color: $primary;
    font-weight: thin;
    cursor: pointer;
    &:hover {
        //font-weight: bold;
        text-decoration: underline;
    }
}

.link-gray {
    //color: $primary;
    font-weight: normal;
    cursor: pointer;
    &:hover {
        //font-weight: bolder;
        text-decoration: underline;
    }
}

.link-emphase {
    cursor: pointer;
    &:hover {
        //font-weight: bold;
        text-decoration: underline;
    }
}

/*
.subtitle {
    display: block;
    width: 70% !important;
    font-size:small;
    color: $secondary;//#9da5b1;
    padding-bottom: 8px;
}*/
//remove/add shadow everywhere:

.button-group-flex {
    max-width: 70%;
}

.card {
    //border: none;
    //margin-inline: 8px;
    //margin-top: 16px;
    //margin-bottom: 4px;
    --cui-card-spacer-y: 1.2rem; 
    --cui-card-spacer-x: 1.2rem;
    //--cui-card-border-color: var(--cui-border-color-translucent);
    
    .card-body {
        //--cui-card-border-radius: var(--cui-border-radius);//0.375rem;
        //--cui-card-border-radius: 0.375rem;
  }
  }
  .details {
    border-left: 1px solid $gray-200;
    div {
        padding-left: 10px;
    }
}
  /*
  .ng-star-inserted {
    font-size: medium;
  }*/
  .progress {
    --cui-progress-bg: var(--cui-progress-bar-bg);
  }

  .shadow {
    //box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
    box-shadow: 0 0.2rem 0.5rem rgba(194, 194, 194, 0.15) !important;
}
.item-shadow {
    box-shadow: 0 0.2rem 0.5rem rgba(76, 14, 175, 0.20) !important;
}

.item-shadow-light {
    border-color: #E8E4FB;
    border-width: 2px;
    box-shadow: 0 0.2rem 0.5rem #EEECF9 !important;
}

.item-shadow-gray {
    box-shadow: 0 0.2rem 0.5rem rgba(112, 112, 112, 0.2) !important;
}


  

svg {
    padding-left: 2px;
    padding-right: 2px;
    &.svg-watermark {
        color: $gray-400;
    }
    &.svg-alert {
        color: $success-75; //$danger;
    }
    &.svg-button {
        color: $primary;
    }
    &.svg-success {
        color: $success;
    }
}

    .svg-button:hover {
        //color: blue;
        filter: drop-shadow(0px 0px 6px rgba(98, 69, 184, 0.5)); 
        z-index: 1000;
  }

.hover-card {
    &:hover {
        background-color: $table-hover-bg;
    }
}


//******************* login view changes:

.authBlock {
    //override:
    max-width: 1000px !important;
    padding: unset !important;
    display: flex;
    //margin: unset !important;
    img {
        width: 50%;
    }
}

.login-card {
    //override:
    display: flex;
    padding: 30px 40px 10px;
    
}
.login-center {
    align-self: center;
    width: 100%;
}

.login-inside {
    width: 100%;
}

.companyDetails {
    text-align: center;
    color: white;
    font-size: small;
    margin-top: 50px;
    a {
        color: white;
    }
}

.gray-text {
    color: $gray-600;
    font-weight: 300;
    //font-size: smaller;
}
.gray-text-smaller {
    color: $gray-600;
    font-weight: 400;
}
.gray-text-light {
    color: $gray-light;
    font-weight: 200;
    //font-size: smaller;
}

.bold-text {
    font-weight: 500;
    line-height: 1.5;
}

.text-alert {
    color: $success-50;
    font-size: 1rem;
}

.badge {
    --cui-badge-font-weight: 400;
    letter-spacing: 2px;
    &.bucket-badge {
        --cui-badge-font-weight: 500;
        --cui-badge-color: #4C0EAF;
        border: solid 1px;
        font-size: 11px;
        //padding-top: 5px;
        align-content: center;
        height: fit-content;
        align-self: center;
    }
  }


.dot::after {
    content: '';
    //position: absolute;
    display: inline-block;
    top: 50%;
    transform: translateY(-60%);
    width: 5px; 
    height: 5px; 
    background-color: #990EAF; 
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
  }
.dd {
    transform: translateY(10%);
}

.no-data {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    color: $gray-400;
    font-style: italic;
}
.violet-card {
    background: $table-hover-bg;// #F6F4FF; //violet cards highligh
    border-width: 2px;
    border-color: $table-hover-bg;
}
.violet-card-light {
    background: $table-hover-bg-light;// #F6F4FF; //violet cards highligh
    border-width: 2px;
    border-color: $table-hover-bg-light;
}

.violet-icon {
    color: $primary-dark;
}
.gray-card {
    background: rgba(128, 128, 128, 0.05);//$table-disable-bg;// #F6F4FF; //violet cards highligh
}

.highlight-frame-dark {
    border-color: $primary-dark;
    border-style: solid;
    border-width: 2px;
}
.no-highlight-frame-dark {
    //keep border thickness so view is not jumping
    
    border-width: 2px;
    //border-color: $table-hover-bg-light;
}
.accordion-button {
    font-weight: $font-weight-medium;
}



c-offcanvas-body {
    padding: unset !important;
    height: calc(100vh - 62px);
    p, div {
      //padding: 20px;
    }
}
.offcanvas {
    --cui-offcanvas-border-width: unset;
    --cui-offcanvas-border-color: unset;
    --cui-offcanvas-width: 650px;
    
    //--cui-offcanvas-box-shadow: 0 0.2rem 0.5rem rgba(194, 194, 194, 0.15) !important;
}

.offcanvas-backdrop {
    --cui-offcanvas-backdrop-bg: rgba(0, 0, 0, 0.40);
}

.detail-item {
    border: solid 1px $gray-400;
    margin: $general-padding;
    padding: $general-padding;
}
.detail-item-highlighted {
    border: solid 2px $primary;
}

.bucket-card:hover {
    background-color: $table-hover-bg;
}
.spinner-loader {
    display: flex;
    justify-content: center; 
    align-items: center;
    min-height: 50px;
    c-spinner {
        margin-right: 10px;
    }
}

/* pills multiselct */
input[type="checkbox"] {
    accent-color: $primary;
}


input[type="checkbox"]:checked {
    background-color: $primary;
    border-color: $primary;
}

.dropdown-content div:hover {
    background-color:  $dropdown-link-hover-bg;
}

.horizontal-separator {
    border-right-style: solid;
    border-right-color: $gray-100;
    border-right-width: 1px;
}


.switchers {
    .form-check-input {
        background-color: unset;
        border-color: $primary;
        background-image: var(--cui-form-switch-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(76, 14, 175,1)'/%3e%3c/svg%3e"));
        &:checked {
            background-color: unset;
            border-color: $primary;
            background-image: var(--cui-form-switch-bg-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(76, 14, 175,1)'/%3e%3c/svg%3e"));
      
        }
    }
}


.view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.fixed-preview-frame {
    border: solid $primary-alt 5px;
    .fixed-preview-label {
        .fixed-preview-label-text {
            background-color: $primary-alt;
        }
        
    }
}

.placeholder-glow {
    display: inline-block;
    width: 100%;
    height: 1em;
    background: #e0e0e0;
    border-radius: $border-radius;
    border-style: solid 0px !important;
    animation: placeholder-glow 1.5s infinite;
  }
  .p-light {
    background: #e0e0e056;
  }
  .p-lighter {
    background: #e0e0e028;
  }
  .p-dark {
    background: #e0e0e0ca;
  }
  .p-high {
    height: 1.2em;
  }
  .p-thin {
    height: 1em;
  }

  c-form-check {
    cursor: pointer;
  }

  .small-score {
    svg {
      width: 50px !important;
      height: 25px !important;
      margin-left: 5px !important;
    }
}

.disabled-ui {
    pointer-events: none; /* Disables all interactions */
    opacity: 0.5;
  }


.aimars-tabs {
    &.nav-pills .nav-link {
        color: unset !important;
        background-color: unset !important;
        border-bottom: solid 1px darkgray;
        border-radius: unset;
        font-weight: bolder;
        &.active {
            border-bottom: solid 3px #4C0EAF;
            color: #4C0EAF !important;
            font-weight: bolder;
        }
  }
}

.color-primary {
    color: $primary;
}

.color-primary-dark {
    color: $primary-dark;
}


.border-radius {
    border-radius: $border-radius;
}

.custom-line {
    height: 2px;              
    background-color: $primary-dark;
    width: 80%;               
    margin: 20px auto;        
}

.nodata-explained {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: $general-padding;
    .msg {
        width: 70%;
        margin: $general-padding;
    }
    
  }
  .small-icon {
    width: 14px;
    height: 14px;
  }

  .h5 {
    font-size: $h5-font-size !important;
  }

  .h5-smaller {
    font-size: 16px;
  }
  .h5-larger {
    font-size: 22px;
  }

  .nav-pills .nav-link {
    color: unset !important;
    background-color: unset !important;
    border-bottom: solid 1px darkgray;
    border-radius: unset;
    font-weight: bolder;
    &.active {
        border-bottom: solid 3px #4C0EAF;
        color: #4C0EAF !important;
        font-weight: bolder;
    }
}

.g-buttons-row {
    button {
        margin-right: $small-padding;
    }
}


  
  app-offcanvas-m1on1 .table-of-cards {
    height: unset !important;
    div.rowi {
        width: 10% !important;
        font-size: small !important;
        font-weight: bolder;

        //white-space: nowrap;
        //overflow: hidden; 
        //text-overflow: ellipsis;
    }
    div.rowi:nth-of-type(2) {
        width: 60% !important;
        //justify-content: center;
    }
    h5 {
        //font-size: smaller !important;
    }
  }

  app-offcanvas-m1on1 {
    h4 {
        display: none !important;
    }
    c-card {
        box-shadow: none !important;
    }

    app-group-button-switcher {
        display: none !important;
    }

    app-card-insights-list {
        div.icons {
            display: none !important;
        }
        .link-primary {
            display: none !important;
        }
        .dot:nth-of-type(1) {
            display: none !important;
        }
    }

  }

  app-offcanvas-m1on1-filters {
    app-m1on1-category-stats-item {
        c-card {
          min-width: 145px !important;
        }
      }
  }

  .font-regular {
    font-size: 14px;
    font-weight: 500;
  }
  .text-capitalize {
    text-transform: capitalize; /* Capitalizes the first letter of each word */
  }
  .dropdown-menu.show {
    z-index: 10000;
  }

@media (max-width: 900px) {
    .authBlock {
        img {
            display: none;
        }
    }
}

.mdc-text-field {
    height: 40px !important;
}/*
mat-label {
    padding: 10px !important;
}
.mat-mdc-button-touch-target {
    height: 30px !important;
}*/
/* datepicker styling */
.mat-form-field {
    width: 100%; /* Full width */
    background-color: #f4f4f4; /* Light background */
    border-radius: 5px; /* Rounded corners */
  }
  
  .mat-form-field-appearance-outline .mat-form-field-outline {
    //color: #6200ea; /* Custom border color */
  }


.mat-datepicker-content {
    background-color: white !important; /* Set background */
    border-radius: 10px !important; /* Round corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
    z-index: 10000;
  }
  
  .mat-calendar-body-cell-content {
    color: #333 !important; /* Change date text color */
    font-weight: bold;
  }
  
  .mat-calendar-body-selected {
    background-color: $primary !important; /* Selected date background */
    color: white !important; /* Selected date text */
    padding: 10px !important;
  }
  
  .mat-calendar-body-today {
    border: 2px solid $primary !important; /* Highlight today */
  }

  .mat-datepicker-toggle {
    color: $gray-light !important; /* Change calendar icon color */
  }
  
  .mat-datepicker-toggle button {
    background: transparent !important;
  }

  .mat-calendar {
    font-size: 12px !important; /* Smaller font */
  }

  .mat-calendar-table {
    //width: 95% !important; /* Smaller font */
  }

  .mat-calendar-table-header-divider {
    border-bottom: solid 1px $gray-100 !important;
    margin-bottom: 10px !important;
  }
.mat-mdc-button-touch-target {

}
/* Make the icon smaller */
.mat-calendar-previous-button .mat-icon, 
.mat-calendar-next-button .mat-icon {
  font-size: 16px !important; /* Default is 24px, reducing to 16px */
}
.mat-calendar-period-button {
    font-size: 18px !important; /* Bigger text */
    font-weight: bold !important;
    color: $primary-dark !important; /* Purple */
  }

  .mat-calendar-previous-button, .mat-calendar-next-button {
    width: 40px !important;  /* Increase button width */
    height: 40px !important; /* Increase button height */
    font-size: 24px !important; /* Make the arrow icon larger */
  }
  svg.mat-calendar-arrow {
        padding: unset !important;
        margin-bottom: 5px !important;
  }
  /* Add animation when clicking a date */
.mat-calendar-body-cell-content {
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  /* When a date is clicked (selected), scale it up slightly */
  .mat-calendar-body-selected {
    background-color: $primary !important; /* Change color (purple example) */
    color: white !important; /* Make text white */
    transform: scale(1.2); /* Slightly increase size on click */
    border-radius: 50%; /* Ensure it's a perfect circle */
  }
  
  /* Hover effect for better UX */
  .mat-calendar-body-cell-content:hover {
    background-color: $primary-10 !important; /* Light purple hover */
    transform: scale(1.1); /* Slightly scale up */
  }
  
  